/* eslint-disable import/no-anonymous-default-export */
import ImportSession, { ImportAddress } from "src/interfaces/importSession";
import { FilterParams } from 'src/global/types';
import axios from 'src/utils/axios';
import ApiTokenSupport from './token.support';


class ImportApi extends ApiTokenSupport {

    /**
   * Get Import Sessions
   * @returns 
   */
    async getImportSessions(filter?: FilterParams): Promise<ImportSession[]> {
      const resp = await axios.post<ImportSession[]>(`/api/import_sessions/filter`, { ...filter } , this.withAuthorization());
      return resp.data;
    }

    async createImportSession( total: number ): Promise<ImportSession> {
      const resp = await axios.post<ImportSession>(`/api/import_sessions`, { total } , this.withAuthorization());
      return resp.data;
    }
    
  async importAddress(payload: any): Promise<ImportSession> {
      const resp = await axios.post<ImportSession>(`/api/import_addresses/resolve-address`, { ...payload } , this.withAuthorization());
      return resp.data;
    }

    async getImportAddressesSession(filter?: FilterParams) {
      const resp = await axios.post<ImportAddress[]>(`/api/import_addresses/filter`, { ...filter } , this.withAuthorization());
      return resp.data;
    }
}

export default new ImportApi();