import React, { useEffect, useState } from "react";
import useModal from "src/hooks/useModal";
import Papa from "papaparse";
import StaticActionBadge from "../../badges/StaticActionBadge";
import "src/components/fields/parseNumberInput.css";
import FuseCoin from "../../icons/FuseCoin";
import AnimatedNumber from "react-awesome-animated-number";
import { motion, AnimatePresence } from "framer-motion";
import useViewport from "src/hooks/useViewport";
import { v4 as uuidv4 } from "uuid";
import NewBulkRow from "./NewBulkRow";
import TrashButton from "src/components/buttons/TrashButton";
import { importApi } from "src/api";
import { useNavigate } from "react-router-dom";
import useImportContext from "src/hooks/private/useImportContext";
import useWatchlist from "src/hooks/private/useWatchlist";
import addresser, { IParsedAddress } from "addresser";
import { getGeocode } from "use-places-autocomplete";
import ButtonBlock from "../ButtonBlock";
import { faFileCsv, faUpload } from "@fortawesome/free-solid-svg-icons";
import { RealeflowSuggestion } from "src/interfaces/property";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";
import useApp from "src/hooks/useAppContext";

export interface CsvAddress {
  id: string;
  address: string;
  isWatchlist: boolean;
  isResearch: boolean;
  isSkiptrace: boolean;
  isDebtstack: boolean;
  source: "file" | "research";
  payload: any;
  error: boolean;
  placeID?: string;
  suggestion?: RealeflowSuggestion;
  hash?: string;
}

const BulkImportModal = () => {
  const { setShowModal } = useModal();

  const { fetchWatchlist } = useWatchlist();

  const { app } = useApp();

  const { subscriptionFeatures } = useCurrentSubscriptionContext();

  const navigate = useNavigate();

  const [csvFile, setCsvFile] = useState<File>();

  const [data, setData] = useState<CsvAddress[]>([]);

  const [error, setError] = useState<null | string>(null);

  const [hasUploaded, setHasUploaded] = useState<boolean>(false);

  const [isImporting, setIsImporting] = useState<boolean>(false);

  const [isReadingCSV, setIsReadingCSV] = useState<boolean>(false);

  const { fetchImportSessions } = useImportContext();

  const [isSomethingSelected, setIsSomethingSelected] =
    useState<boolean>(false);

  const [isCheckedDealio, setIsCheckedDealio] = useState<boolean>(false);

  const createNewID = uuidv4;

  const handleChangeFile = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);

    if (!files) {
      return;
    }

    if (files[0].type !== "text/csv") {
      setError("File must be .csv");
      return;
    }

    setCsvFile(files[0]);
  };

  const isValidAddressFormat = (address: string): boolean => {
    return address.length > 5;
  };

  const extractDealioData = (
    csvContent: string
  ): { address: string; hash: string }[] => {
    // Define the desired columns
    const requiredColumns = [
      "PropertyAddress",
      "PropertyCity",
      "PropertyState",
      "PropertyPostalCode",
      "AddressHash",
    ];

    let missingColumns: string[] = [];
    let hasMissingColumns = false;

    const result = Papa.parse(csvContent, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results: any) => {
        // Check for missing columns in the parsed header
        missingColumns = requiredColumns.filter(
          (column) => !results.meta.fields.includes(column)
        );
        hasMissingColumns = missingColumns.length > 0;
      },
    });

    // Handle missing columns error
    if (hasMissingColumns) {
      const missingColumnsString = missingColumns.join(", ");
      setError(
        `The following required columns are missing: ${missingColumnsString}`
      );
      return [];
    }

    // Transform the parsed data to the desired format
    const dataTransformed = result.data.map((row: any) => ({
      address: `${row.PropertyAddress}, ${row.PropertyCity}, ${row.PropertyState} ${row.PropertyPostalCode}`,
      hash: row.AddressHash,
    }));

    return dataTransformed;
  };

  const readCSV = () => {
    setError("");
    if (!csvFile) return setError("Enter a valid file");

    setIsReadingCSV(true);

    const reader = new FileReader();

    reader.onload = async ({ target }: any) => {
      let dataArr: CsvAddress[] = [];

      if (isCheckedDealio) {
        // Dealio Import

        let extractedData: { address: string; hash: string }[] = [];
        extractedData = extractDealioData(target.result);
        if (extractedData.length > 300) {
          setError("You may only import up to 300 addresses at a time.");
          return;
        }

        for (const { address, hash } of extractedData) {
          try {
            const [payload] = await getGeocode({ address });

            if (!payload) continue;

            const csvAddress: CsvAddress = {
              id: createNewID(),
              address: address,
              hash: hash,
              isWatchlist: false,
              isResearch: false,
              isSkiptrace: false,
              isDebtstack: false,
              source: "file",
              payload: payload,
              placeID: payload.place_id,
              error: false,
            };

            dataArr.push(csvAddress);
          } catch (error) {
            console.error(
              `Failed to get Google Data for address ${address}`,
              error
            );
            // Depending on your error handling, you may want to set an error state or log the error.
          }
        }
      } else {
        // CSV Import

        const csv = Papa.parse<string[]>(target.result, { header: false });
        const { data } = csv;
        // Trim empty cells
        let flattenedData: string[] = data
          .reduce((a: any, b: any) => {
            return a.concat(b);
          }, [])
          .filter((address: string) => address && address.trim() !== "");

        // Remove duplicates
        flattenedData = flattenedData.filter(
          (address, index, self) => self.indexOf(address) === index
        );

        // Check if address is less than 3 characters which means the CSV is not formatted properly
        for (const address of flattenedData) {
          if (address.length <= 3) {
            setError(
              "Addresses must be in a single column. Please format your CSV correctly."
            );
            setIsReadingCSV(false);
            return;
          }
        }

        if (flattenedData.length > 300) {
          setError("You may only import up to 300 addresses at a time.");
          return;
        }

        for (const address of flattenedData) {
          if (!address || !address.length) continue;

          const isValid = isValidAddressFormat(address);
          if (!isValid) continue; // Skip invalid addresses

          try {
            const [payload] = await getGeocode({ address });

            if (!payload) {
              continue;
            }

            const parsedAddress: IParsedAddress =
              addresser.parseAddress(address);

            const { addressLine1, placeName, stateAbbreviation, zipCode } =
              parsedAddress;

            const formattedAddress: string = zipCode
              ? `${addressLine1} ${placeName}, ${stateAbbreviation} ${zipCode}`
              : `${addressLine1} ${placeName}, ${stateAbbreviation}`;

            const csvAddress: CsvAddress = {
              id: createNewID(),
              address: formattedAddress,
              isWatchlist: false,
              isResearch: false,
              isSkiptrace: false,
              isDebtstack: false,
              source: "file",
              payload,
              placeID: payload.place_id,
              error: false,
            };

            dataArr.push(csvAddress);
          } catch (error) {
            console.error(
              `Failed to get Google Data for address ${address}`,
              error
            );
          }
        }
      }

      console.log(dataArr);

      setData(dataArr);
      setHasUploaded(true);
      setIsReadingCSV(false);
    };
    reader.readAsText(csvFile);
  };

  const handleAddManually = () => {
    setData([
      {
        id: createNewID(),
        address: "",
        isWatchlist: false,
        isResearch: false,
        isSkiptrace: false,
        isDebtstack: false,
        source: "research",
        payload: {},
        error: false,
      },
    ]);
    setHasUploaded(true);
  };

  const handleUpload = () => {
    readCSV();
  };

  const validateInput = (): Promise<CsvAddress[]> => {
    return new Promise((resolve) => {
      const validatedData = data
        .map((row: CsvAddress) => {
          if (!row.address.length) {
            return { ...row, error: true };
          } else {
            return { ...row, error: false };
          }
        })
        .filter(
          (row: CsvAddress) =>
            row.isWatchlist ||
            row.isResearch ||
            row.isSkiptrace ||
            row.isDebtstack
        );

      setData(validatedData);
      resolve(validatedData);
    });
  };

  type ObjectType = {
    error: boolean;
    [key: string]: any;
  };

  function checkErrorStatus(objects: ObjectType[]): boolean {
    for (let obj of objects) {
      if (obj.error) {
        return false;
      }
    }
    return true;
  }

  const handleImport = async () => {
    const validatedData = await validateInput();
    if (checkErrorStatus(validatedData)) {
      setIsImporting(true);
      try {
        const session = await importApi.createImportSession(
          validatedData.length
        );
        for (let i = 0; i < validatedData.length; i++) {
          await new Promise((resolve) => setTimeout(resolve, i * 1000));
          await importApi.importAddress({
            sessionID: session.id,
            ...validatedData[i],
          });
        }
        fetchImportSessions();
        navigate("/import-history", {
          state: { sessionID: session.id },
        });
      } catch (err) {
        console.log(err);
      } finally {
        setIsImporting(false);
        setShowModal(false);
        fetchWatchlist();
      }
    }
  };

  const calculateFuseCoinCost = (): number => {
    let totalCoins: number = 0;
    data.forEach((obj: CsvAddress) => {
      if (obj.isResearch)
        totalCoins =
          totalCoins + (subscriptionFeatures?.Research.coinCost || 1);
      if (obj.isSkiptrace)
        totalCoins =
          totalCoins + (subscriptionFeatures?.SkipTrace.coinCost || 8);
      if (obj.isDebtstack)
        totalCoins =
          totalCoins + (subscriptionFeatures?.DebtStack.coinCost || 12);
    });
    return totalCoins;
  };

  const handleInsertNewRow = () => {
    const newArray: CsvAddress[] = [
      ...data,
      {
        id: createNewID(),
        address: "",
        isWatchlist: false,
        isResearch: false,
        isSkiptrace: false,
        isDebtstack: false,
        source: "research",
        payload: {},
        error: false,
      },
    ];
    setData(newArray);
  };

  const { height } = useViewport();

  const calculateMaxHeight = () => {
    return height - 350;
  };

  useEffect(() => {
    const checkIfSelected = () => {
      for (let row of data) {
        if (
          row.isWatchlist ||
          row.isResearch ||
          row.isSkiptrace ||
          row.isDebtstack
        ) {
          setIsSomethingSelected(true);
          setError(null);
          return; // Exit the function early if something is selected
        }
      }
      setIsSomethingSelected(false);
    };

    checkIfSelected();
  }, [data]);

  const handleSelectError = () => {
    setError("Please select an action to perform on an address");
  };

  const [selectAllRowsState, setSelectAllRowsState] = useState({
    watchlist: false,
    research: false,
    skipTrace: false,
    debtStack: false,
  });

  type ToggleKey = "watchlist" | "research" | "skipTrace" | "debtStack";

  const selectAllRows = (key: ToggleKey) => {
    const toggleValue = !selectAllRowsState[key];

    let updatedState = {
      ...selectAllRowsState,
      [key]: toggleValue,
    };

    // If research is off and either skipTrace or debtStack is selected, turn on research
    if (
      !updatedState.research &&
      (key === "skipTrace" || key === "debtStack")
    ) {
      updatedState.research = true;
    }

    // If research is deselected, also turn off debtStack and skipTrace
    if (key === "research" && !toggleValue) {
      updatedState.skipTrace = false;
      updatedState.debtStack = false;
    }

    setSelectAllRowsState(updatedState);

    // Update the data array based on the new state for the specified key
    const updatedData = data.map((row) => {
      const updatedRow = {
        ...row,
        [keyMapping(key)]: toggleValue,
      };

      // If research is off and either skipTrace or debtStack is selected, turn on research for the row
      if (!row.isResearch && (key === "skipTrace" || key === "debtStack")) {
        updatedRow.isResearch = true;
      }

      // If research is deselected, also turn off debtStack and skipTrace for the row
      if (key === "research" && !toggleValue) {
        updatedRow.isSkiptrace = false;
        updatedRow.isDebtstack = false;
      }

      return updatedRow;
    });

    setData(updatedData); // Assuming you have a setData function to update the data state
  };

  // Helper function to map the ToggleKey to the corresponding key in the data row
  const keyMapping = (key: ToggleKey): keyof (typeof data)[0] => {
    switch (key) {
      case "watchlist":
        return "isWatchlist";
      case "research":
        return "isResearch";
      case "skipTrace":
        return "isSkiptrace";
      case "debtStack":
        return "isDebtstack";
    }
  };

  return (
    <AnimatePresence>
      <motion.div key={"BulkImportModal"} layout>
        {hasUploaded ? (
          <>
            <motion.div
              layout
              className="overflow-x-none flex  columns-1 flex-col items-center justify-center overflow-y-auto"
            >
              <div className="mb-2 flex items-center justify-center gap-2">
                <div className="join">
                  <div className="hover-border-secondary btn join-item btn-sm border-r-0 border-secondary bg-back-light hover:border-secondary hover:bg-back-light dark:bg-base-100 hover:dark:bg-base-100">
                    <FuseCoin />
                  </div>
                  <div className="btn join-item btn-sm border-l-0 border-secondary bg-back-light hover:border-secondary hover:bg-back-light dark:bg-base-100 hover:dark:bg-base-100">
                    <AnimatedNumber
                      value={calculateFuseCoinCost()}
                      className="font-bold text-text-dark dark:text-text-light"
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-outline btn-secondary btn-sm hover:text-white"
                    onClick={handleInsertNewRow}
                  >
                    + New Row
                  </button>
                </div>
              </div>
              {error && <p className="text-error">{error}</p>}
              <div></div>
              <motion.ul layout className="px-4">
                <motion.li
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="flex flex-row"
                >
                  <div className="flex w-10 items-center justify-center">
                    <StaticActionBadge
                      title={"Watchlist"}
                      iconOnly={true}
                      onClick={() => selectAllRows("watchlist")}
                    />
                  </div>
                  <div className="flex w-10 items-center justify-center">
                    <StaticActionBadge
                      title={"Research"}
                      iconOnly={true}
                      onClick={() => selectAllRows("research")}
                    />
                  </div>
                  <div className="flex w-10 items-center justify-center">
                    <StaticActionBadge
                      title={"Skip Trace"}
                      iconOnly={true}
                      onClick={() => selectAllRows("skipTrace")}
                    />
                  </div>
                  <div className="flex w-10 items-center justify-center">
                    <StaticActionBadge
                      title={"Debt Stack"}
                      iconOnly={true}
                      onClick={() => selectAllRows("debtStack")}
                    />
                  </div>
                  <div className="flex items-center justify-center">
                    <p className="w-96 text-left font-bold text-secondary">
                      Address
                    </p>
                  </div>
                  <div className="invisible ml-2 flex items-center justify-center">
                    <TrashButton onClick={() => {}} isSmall={true} />
                  </div>
                </motion.li>
              </motion.ul>
              <ul
                style={{
                  maxHeight: `${calculateMaxHeight()}px`,
                }}
                className="overflow-scroll px-4"
              >
                {(data || []).map(
                  (
                    {
                      id,
                      address,
                      isWatchlist,
                      isResearch,
                      isSkiptrace,
                      isDebtstack,
                      source,
                      error,
                    },
                    index
                  ) => (
                    <NewBulkRow
                      id={id}
                      key={id}
                      address={address}
                      isWatchlist={isWatchlist}
                      isResearch={isResearch}
                      isSkiptrace={isSkiptrace}
                      isDebtstack={isDebtstack}
                      data={data}
                      setData={setData}
                      index={index}
                      source={source}
                      error={error}
                    />
                  )
                )}
              </ul>

              <div className="mt-4">
                <ButtonBlock
                  submitIcon={faFileCsv}
                  submitLabel="Import"
                  submitting={isImporting}
                  handleSubmit={
                    isSomethingSelected ? handleImport : handleSelectError
                  }
                />
              </div>
            </motion.div>
          </>
        ) : (
          <form>
            <div className="mb-2">
              <input
                type="file"
                className="file-input file-input-bordered file-input-secondary w-full max-w-xs bg-card-light dark:bg-card-dark dark:text-white sm:max-w-sm [&::file-selector-button]:text-text-light"
                onChange={handleChangeFile}
              />
              {error && <p className="mt-2 text-error">{error}</p>}
              {app?.displayDealio && (
                <div
                  className="form-control mb-4 flex w-full flex-row items-center justify-center"
                  onClick={() => setIsCheckedDealio(!isCheckedDealio)}
                >
                  <input
                    type="checkbox"
                    className="checkbox-secondary checkbox checkbox-sm"
                    checked={isCheckedDealio}
                    onChange={() => {}}
                  />
                  <label className="label cursor-pointer">
                    <span className="label-text">
                      This file was exported from Dealio
                    </span>
                  </label>
                </div>
              )}
            </div>
            <div>
              or
              <span
                className="cursor-pointer text-secondary hover:underline"
                onClick={handleAddManually}
              >
                {" "}
                Add Manually
              </span>
            </div>
            <div className="mt-4">
              <ButtonBlock
                handleSubmit={handleUpload}
                submitting={isReadingCSV}
                submitLabel="Upload"
                submitIcon={faUpload}
              />
            </div>
          </form>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default BulkImportModal;
